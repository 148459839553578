import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Hidden } from '@material-ui/core';
import CustomizedSnackbars from '../MySnackbarContentWrapper';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        fontSize: 14
    },
    page_title: {
        fontSize: 23
    },
    svg_icon: {
        height: 24,
        width: 24
    },
    routelink: {
        color: '#1976d2',
        fontSize: 14
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 36,
        backgroundColor: '#1976d2',
        fontSize: 14
    }
}));


function SignIn(props) {
    const classes = useStyles();
    const {
        email, password, handleFieldChange, error, unsetError, disableForm, show } = props;

    const [open, setOpen] = React.useState(false);
    const [openErrors, setOpenErrors] = React.useState(false);
    useEffect(() => {
        if (props.showErrorModal) {
            //setErrorMessage(props.error);
            setOpenErrors(true);
        }
        else {
            setOpenErrors(false);
        }
    });

    const [errormessage, setErrorMessage] = React.useState('');
    const handleSubmit = (event) => {

        props.handleSubmit(event, 'signin');


    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon className={classes.svg_icon} />
                </Avatar>
                <Typography component="h1" variant="h5" className={classes.page_title}>
                    Sign in
        </Typography>
                <ValidatorForm className={classes.form} onSubmit={(event) => handleSubmit(event, 'signin')} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                disabled={disableForm}
                                value={email}
                                onChange={handleFieldChange}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                disabled={disableForm}
                                value={password}
                                className={classes.input}
                                onChange={handleFieldChange}
                                validators={['required']}
                                errorMessages={['this field is required']}

                            /></Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                        </Grid>
                    </Grid>
                    {/* <div className={classes.mt}>
                        {error
                            && (
                                <Message
                                    color="error"
                                    onClose={!disableForm && unsetError}
                                >{error}</Message>
                            )
                        }

                    </div> */}
                    {open ? <CustomizedSnackbars message={errormessage} {...props} /> : null}
                    {openErrors ? <CustomizedSnackbars message={props.error} {...props} /> : null}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={disableForm}
                    >
                        Sign In
          </Button>
                    {/*  
                        <Grid container>
                            <Grid item xs>
                                <Link href="forgotPassword" variant="body2" className={classes.routelink}>
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2" className={classes.routelink}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    */}
                </ValidatorForm>
            </div>
            {/* <Box mt={8}>
                <Copyright />
            </Box> */}
        </Container>
    );
}

export default SignIn;