import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import Modal from 'react-bootstrap/Modal';
import CustomizedSnackbars from '../MySnackbarContentWrapper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
function ShowModal(props) {
  const rowData = props.data;
  function getActions(action, str) {
    return action += '  ' + str.action_type;
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          App Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rowData.length > 0 ?
          <Table>
            <TableBody>
              <TableRow><TableCell><b>Id:</b></TableCell><TableCell>{rowData[0].id}</TableCell></TableRow>
              <TableRow><TableCell><b>Name:</b></TableCell><TableCell>{rowData[0].name}</TableCell></TableRow>
              <TableRow><TableCell><b>Description:</b></TableCell><TableCell>{rowData[0].description}</TableCell></TableRow>
              <TableRow><TableCell><b>Post-Action Type :</b></TableCell><TableCell>{rowData[0].post_actions ? rowData[0].post_actions.reduce(getActions, '') : ''}</TableCell></TableRow>
              {rowData[0].post_actions ? rowData[0].post_actions.map((action) => { return <TableRow><TableCell><b>{'Post Action Rule for ' + action.action_type} : </b></TableCell><TableCell>{action.rule ? action.rule : 'none'}</TableCell></TableRow> }) : ''}
              <TableRow><TableCell><b>Wrapper Html:</b></TableCell><TableCell><div dangerouslySetInnerHTML={{ __html: rowData[0].html }}></div></TableCell></TableRow>
            </TableBody>
          </Table>
          : <p>No data to display</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={props.onHide} >
          Close
                </Button>
      </Modal.Footer>
    </Modal>
  );
}
const modalStyles = makeStyles(theme => ({
  modal70w: {
    width: 500
  },
  btn_primary: {
    marginRight: 15
  }
})
)

/* function SigninModal(props) {
    const classes = modalStyles();
    return (
        <Modal onHide={props.onHide} show={props.show} style={props.style}
            dialogClassName={classes.modal70w}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body >
                <SignIn {...props} />
            </Modal.Body>
        </Modal>
    );
} */
function EditModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit App
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={props.onHide} >
          Close
                </Button>
      </Modal.Footer>
    </Modal>
  );
}

function DeleteModalMultiple(props) {
  const classes = modalStyles();
  const deleteAppFunction = props.deleteappfunction;
  const ids = props.id;
  const handleClick = () => {
    ids.forEach((id)=>deleteAppFunction(id));
    props.onHide();
  }
  return (
    <Modal
      {...props}
      dialogClassName={classes.modal70w}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete App Confirmation
                </Modal.Title><br></br>
        Are you sure you want to delete selected Apps?

      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button type="submit" variant="contained" color="primary" className={classes.btn_primary} onClick={handleClick}>
            Confirm
                            </Button>
          <Button variant="contained" onClick={props.onHide} >
            Cancel
                            </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function DeleteModal(props) {
  const classes = modalStyles();
  const deleteAppFunction = props.deleteappfunction;
  const id = props.id;
  const handleClick = () => {
    deleteAppFunction(id);
    props.onHide();
  }
  return (
    <Modal
      {...props}
      dialogClassName={classes.modal70w}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>

            </Modal.Header> */}
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete App Confirmation
                </Modal.Title><br></br>
        Are you sure you want to delete this App?

            </Modal.Body>
      <Modal.Footer>
        <div>
          <Button type="submit" variant="contained" color="primary" className={classes.btn_primary} onClick={handleClick}>
            Confirm
                            </Button>
          <Button variant="contained" onClick={props.onHide} >
            Cancel
                            </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}


const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'App Name' },
  { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
  //{ id: 'post_actions_type', numeric: true, disablePadding: false, label: 'Post-Action Type' },
  { id: 'updated_at', numeric: true, disablePadding: false, label: 'Last Updated' },
  { id: 'Show', numeric: true, disablePadding: false, label: '' },
  { id: 'edit', numeric: true, disablePadding: false, label: '' },
  /* { id: 'delete', numeric: true, disablePadding: false, label: '' } */
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)} style={{ fontWeight: 600 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  icon_color: {
    color: '#1976d2'
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected,deleteAppAll } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
              App List
          </Typography>
          )}
      </div>


      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={(event)=>deleteAppAll(event)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </Toolbar>

  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableApp: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  icon_color: {
    color: '#1976d2'
  },
  create_new: {
    paddingLeft: 10
  }
}));

export default function AppListTable(props) {
  const classes = useStyles();
  const appsData = props.appsData;
  const getAppDetails = props.getAppDetails;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('updated_at');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { modalshow } = props;


  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = appsData.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const [rowData, setrowData] = React.useState([]);
  const [rowId, setrowId] = React.useState('');
  const [Ids, setIds] = React.useState([]);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [modalShow5, setModalShow5] = React.useState(false);
  const [openErrors, setOpenErrors] = React.useState(false);
  useEffect(() => {
    if (props.showErrorModal) {
      //setErrorMessage(props.error);
      setOpenErrors(true);
    }
    else {
      setOpenErrors(false);
    }
  });


  function showApp(event, id) {
    event.stopPropagation();
    setrowData(appsData.filter(element => element.id == id));
    setModalShow1(true);
  }

  function deleteAppAll(){
    setIds(selected);
    setModalShow5(true);
  }

  const deleteApp = (event, id) => {
    event.stopPropagation();
    setrowId(id);
    setModalShow4(true);

  }
  const handleViewChange = props.handleviewchange;
  const handleView = (elementid, id) => {
    if (id != undefined && id != null)
      getAppDetails(id);

    if (elementid == 'CreateApp')
      handleViewChange(elementid);
  }
  function getActions(action, str) {
    return action += '  ' + str.action_type;
  }


  return (
    <div className={classes.root}>
      {openErrors ? <CustomizedSnackbars message={props.error} {...props} /> : null}
      <Paper className={classes.paper}>
        <IconButton aria-label="Create new App" className={classes.icon_color} onClick={() => handleView("CreateApp")}>
          <AddCircleIcon />
          <Typography variant="h6" className={classes.create_new}>
            Create New App
                </Typography>
        </IconButton>
        <EnhancedTableToolbar numSelected={selected.length} onSelectAllClick={handleSelectAllClick} deleteAppAll={deleteAppAll}/>
        <div className={classes.tableApp}>
          {appsData ? appsData.length > 0 ?


            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={appsData.length}
              />
              <TableBody>
                {stableSort(appsData, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        {/* <TableCell align="right">{row.post_actions ? row.post_actions.reduce(getActions, '') : ''}</TableCell> */}
                        <TableCell align="right">{row.updated_at}</TableCell>
                        <TableCell padding="checkbox">
                          <Tooltip title="Visibility">
                            <IconButton aria-label="Show" onClick={event => showApp(event, row.id)} className={classes.icon_color}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Tooltip title="Edit">
                            <IconButton aria-label="Edit" onClick={() => handleView("EditApp", row.id)} className={classes.icon_color}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {/* <TableCell padding="checkbox">
                          <Tooltip title="delete">
                            <IconButton aria-label="Delete" onClick={(event) => deleteApp(event, row.id)} className={classes.icon_color}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            : <center>No data to display</center> : <center>No data to display</center>}
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={appsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <ShowModal
        show={modalShow1} data={rowData}
        onHide={() => setModalShow1(false)} style={{ opacity: 1 }}
      />
      <EditModal
        show={modalShow2}
        onHide={() => setModalShow2(false)} style={{ opacity: 1 }}
      />
      <DeleteModal
        show={modalShow4} id={rowId} deleteappfunction={props.deleteApp}
        onHide={() => setModalShow4(false)} style={{ opacity: 1 }}
      />

      <DeleteModalMultiple
      show={modalShow5} id={Ids} deleteappfunction={props.deleteApp}
      onHide={() => setModalShow5(false)} style={{ opacity: 1 }}
    />

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
