import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css';
import 'froala-editor/css/plugins/code_view.min.css'


// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
//import ReactHeader from './ReactHeader';
//mport asyncValidate from './asyncValidate'

const validate = values => {
    const errors = {}
    const requiredFields = [
        'Name',
        'Description',
        'App Category',
        'Provider',
        //'notes'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    /* if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    } */
    return errors
}

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
        <TextField
            label={label}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
        />
    )

const renderCheckbox = ({ input, label }) => (
    <div>
        <FormControlLabel
            control={
                <Checkbox
                    checked={input.value ? true : false}
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    </div>
)

const radioButton = ({ input, ...rest }) => (
    <FormControl>
        <RadioGroup {...input} {...rest}>
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
    </FormControl>
)

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
}

const renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
}) => (
        <FormControl error={touched && error}>
            <InputLabel htmlFor="age-native-simple">Age</InputLabel>
            <Select
                native
                {...input}
                {...custom}
                inputProps={{
                    name: 'age',
                    id: 'age-native-simple'
                }}
            >
                {children}
            </Select>
            {renderFromHelper({ touched, error })}
        </FormControl>
    )
const useStyles = makeStyles(theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: 20
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    icon_color: {
        color: '#1976d2'
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        paddingTop: 8,
        paddingBottom: 8
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        fontSize: 14,
        padding: 20
    },
    page_title: {
        fontSize: 14
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 36,
        backgroundColor: '#1976d2',
        fontSize: 14
    },
    FroalaEditor: {
        paddingTop: 8,
        paddingBottom: 8
    }
}));


const MaterialUiForm = props => {
    const classes1 = useStyles();
    const { handleSubmit, pristine, reset, submitting, classes } = props;
    const config = {
        placeholderText: 'Edit Your Content Here!',
        charCounterCount: false,
        pluginEnabled: ['aviary', 'image', 'fontFamily', 'fontSize', 'help', 'colors', 'paragraphStyle', 'align', 'lists', 'outdent', 'indent', 'paragraphFormat', 'specialCharacters', 'hr', 'clearFormatting', 'link', 'embedly', 'file', 'table', 'undo', 'redo', 'spellchecker'],
        toolbarButtons: [['bold', 'italic', 'underline'], ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight'], ['insertLink', 'insertImage', 'insertVideo'], ['undo', 'redo', 'html']],
        codeBeautifierOptions: {
            end_with_newline: true,
            indent_inner_html: true,
            extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
            brace_style: 'expand',
            indent_char: ' ',
            indent_size: 4,
            wrap_line_length: 0
        },
        htmlRemoveTags: []


    };

    return (
        <React.Fragment>
            <div className={classes1.root}>
                <Paper className={classes1.paper}>
                    <Typography component="h1" variant="h5" className={classes1.page_title}>
                        App Details Form
                    </Typography>
                    <form onSubmit={handleSubmit} >
                        <div className={classes1.input}>
                            <TextField
                                name="Name"
                                //component={renderTextField}
                                label="Name"
                                variant="outlined"
                            />
                        </div>
                        <div className={classes1.input}>
                            <TextField name="Description" label="Description" variant="outlined" />
                        </div>
                        <div className={classes1.input}>
                            <TextField name="App Category" label="App Category" variant="outlined" />
                        </div>
                        <div className={classes1.input}>
                            <TextField
                                name="Provider"
                                //component={renderTextField}
                                label="Provider"
                                variant="outlined"
                            />
                        </div>
                        <div className={classes1.FroalaEditor}>
                            <FroalaEditor
                                tag='textarea'
                                config={config}
                            //model={this.state.model}
                            //onModelChange={this.handleModelChange}
                            />
                            <FroalaEditorView
                            //model={this.state.content}
                            />
                        </div>
                        <div>
                            <Button variant="contained" color="primary" disabled={pristine || submitting} className={classes1.button}>
                                Submit
                            </Button>
                            <Button variant="contained" className={classes1.button} disabled={pristine || submitting} onClick={reset}>
                                Clear Values
                            </Button>
                        </div>
                    </form>
                </Paper>
            </div>
        </React.Fragment>
    )
}

export default MaterialUiForm//({
    //form: 'MaterialUiForm', // a unique identifier for this form
    //validate,
    //asyncValidate
//})(MaterialUiForm)
