import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux';
//import { Form, FormGroup, Input, Button, FormFeedback, Alert } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import * as signupActionCreator from './actions';
//import Validator from './validators';
import Forgotpassword from './forgotPassword';
//import ROUTES from '../utils/routeConstants';

class ForgotpasswordPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            email: '',
            platform: undefined,
            isVerifiedPlatform: false
        };
        //this.validators = new Validator();
        //this.isValid = false;
        //this.forgotPasswordUser = this.forgotPasswordUser.bind(this);
        //this.handleInputChange = this.handleInputChange.bind(this);
        //this.closeErrorNotification = this.closeErrorNotification.bind(this);
    }

    handleFieldChange = (e) => {
        console.log('inside handleFieldChange');
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleSubmit = () => {
        event.preventDefault();
        console.log('inside handlesubmit');
        console.log(this.props);
        const { sendSignupRequest } = this.props;

        const { fullname, email, password } = this.state;
        sendSignupRequest({ fullname, email, password });
        this.signupUser({ fullname, email, password });

    }

    handleInputChange(event, inputPropName) {

        let newUserConfig = { ...this.state.userConfig };
        if (inputPropName === 'organization_name') {
            newUserConfig.organization.name = event.target.value;
        } else {
            newUserConfig[inputPropName] = event.target.value;
        }
        this.setState({ userConfig: newUserConfig });
        this.updateValidator(inputPropName, event.target.value);
    }

    closeErrorNotification() {
        this.props.clearSignupError();
    }

    updateValidator(fieldName, value) {
        const fieldVd = this.validators[fieldName];
        fieldVd.errorMsg = '';
        fieldVd.state = value;
        fieldVd.valid = true;
        fieldVd.value = value;
        fieldVd.touched = true;
        const rules = fieldVd.rules;
        for (let i = 0; i < rules.length; i++) {
            if (!rules[i].test(value)) {
                fieldVd.errorMsg = rules[i].message;
                fieldVd.valid = false;
                this.isValid = false;
                break;
            }
        }
    }

    isFormValid() {
        const fieldNames = Object.keys(this.validators);
        this.isValid = true;
        for (let i = 0; i < fieldNames.length; i++) {
            this.updateValidator(fieldNames[i], this.validators[fieldNames[i]].value);
        }
        console.log('this.isValid', this.isValid);
        return this.isValid;
    }

    signupUser(obj) {
        /* if (this.isFormValid()) {
            let userConfig = {
                ...this.state.userConfig
            };
            this.props.sendSignupRequest(userConfig);
        } else {
            this.setState({ userConfig: { ...this.state.userConfig } });
        } */

        console.log('Signup Action');
        const headers = {
            'Content-Type': 'application/json'
        };
        var LOGIN_API_URL = "https://cd-sso-api-dev.pymx6.com/users/signup";
        //if (this.validateForm()) {
        //return dispatch => {
        //dispatch({ type: 'SEND_LOGIN_REQUEST' });
        const { signupCompleted } = this.props;
        return axios.post(LOGIN_API_URL, obj, { header: headers })
            .then(response => response.data)
            .then(res => {
                if (res.status.status_code == 1001) {
                    alert('SignUP was successfull! Login using your credentials.');
                    signupCompleted(res);
                }
                else if (res.status.status_code == 1302) {
                    alert("SignUP failed! User with given email already exists");

                }
            })
            .catch(err => {
                console.log(err);
            })
        /* return myAjaxLib.post(`https://cd-sso-api-dev.pymx6.com/users/login?service=http://coriolis.co.in`, obj)
            .then(response => response.json())
            .then(json => dispatch(getLoginSuccess(json)))
            .catch(error => console.log(error)); */
        //}
        //else {
        //    alert('Form cannot be submitted!');
        //}

    }

    render() {
        if (this.props.signUpSuccess) {
            //if (this.props.actualRoute) {
            return <Redirect to={'/signin'} />;
            //}
            //return <Redirect to={ROUTES.SITE_REG} />;
        }
        const { fullname, email, password } = this.state;
        const { errorMsg, clearLoginError, isLoggedIn, actualRoute, signUpSuccess } = this.props;
        const inputProps = {
            fullname,
            email,
            password,
            handleFieldChange: this.handleFieldChange,
            handleSubmit: this.handleSubmit,
            error: errorMsg,
            unsetError: clearLoginError,
            //disableForm: !this.validateForm,
            /* forgetPasswordLink: {
                pathname: ROUTES.FORGOT_PASSWORD,
                state: {
                    platformId: this.platform,
                },
            }, */
        };
        //let errorMsg;
        //if (this.props.errorMsg) {
        //errorMsg = <Alert color='danger' toggle={this.closeErrorNotification}>{this.props.errorMsg}</Alert>
        //}

        return (

            <Fragment>
                {

                    <Forgotpassword {...inputProps} />
                    //<ClearAttentionForm {...inputProps} />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        //...ownProps,
        isLoggedIn: state.isLoggedIn,
        //actualRoute: state.app.actualRoute,
        //errorMsg: state.forgotPassword.errorMsg,
        //showSuccess: state.forgotPassword.showSuccess,
        //isVerifiedPlatform: state.forgotPassword.isVerifiedPlatform,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendForgotPasswordRequest: (forgotPasswordDetails) => dispatch(forgotPasswordActionCreator.sendForgotPasswordRequest(forgotPasswordDetails)),
        clearForgotError: () => dispatch(forgotPasswordActionCreator.clearForgotError()),
        clearForgotFields: () => dispatch(forgotPasswordActionCreator.clearForgotFields()),
        verifyPlatform: (details) => dispatch(forgotPasswordActionCreator.verifyPlatform(details))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotpasswordPage);