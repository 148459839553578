import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import errorHandlerReducer from '../reducers/errorHandlerReducer';
import rootReducer from '../reducers/rootReducer';

const rootReducers = combineReducers({
  rootReducer: rootReducer,
  errorHandler: errorHandlerReducer
})

export default function configureStore() {
  const store = createStore(
    rootReducers,
    //initialState,
    applyMiddleware(thunk)
  );
  return store
}
