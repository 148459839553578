import ACTIONS from './constants';

export const sendSignupRequest = (signupDetails) => {
    return {
        type: ACTIONS.SEND_SIGNUP_REQUEST,
        payload: signupDetails
    };
}

export const signupCompleted = (data) => {
    return {
        type: ACTIONS.SIGNUP_COMPLETED,
        payload: data
    };
}

export const signupErrorOccurred = (errors) => {
    console.log('errors: ', errors);
    return {
        type: ACTIONS.SIGNUP_ERROR_OCCURRED,
        payload: errors
    };
}

export const clearSignupError = () => {
    return {
        type: ACTIONS.CLEAR_SIGNUP_ERROR
    };
}

export const sendInviteCode = (inviteCode) => {
    return {
        type: ACTIONS.SEND_INVITE_CODE,
        payload: { invite_code: inviteCode }
    };
}

export const inviteCodeValidated = (inviteCode) => {
    return {
        type: ACTIONS.INVITE_CODE_VALIDATED,
        payload: inviteCode
    };
}

export const clearInviteCode = () => {
    return {
        type: ACTIONS.CLEAR_INVITE_CODE
    };
}

export const inviteCodeErrorOccurred = (errors) => {
    return {
        type: ACTIONS.INVITE_CODE_ERROR_OCCURED,
        payload: errors
    };
}

export const clearInviteCodeError = () => {
    return {
        type: ACTIONS.CLEAR_INVITE_CODE_ERROR
    };
}