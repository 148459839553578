import React, { Fragment, PureComponent, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import axios from 'axios';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import { object } from 'prop-types';
import MySnackbarContentWrapper from './MySnackbarContentWrapper';
import CustomizedSnackbars from './MySnackbarContentWrapper';
const useStyles = makeStyles(theme => ({

  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: 20
  },
  heading: {
    fontSize: 16,
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingTop: 6,
    paddingLeft: 350
  },
  table: {
    minWidth: 750,
  },
  tablecampaigns: {
    //display: 'none',
    minWidth: 375

  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  campaign_panel: {
    marginBottom: 10
  },
  submit_btn: {
    marginTop: 10,
    marginBottom: 10
  },
  panel_height: {
    margin: -15
  }
}
)
);

function Settings(props) {
  const classes = useStyles();
  const serviceUrl = props.serviceUrl;
  const getSettingsData = props.getSettingsData;
  const settingsData = props.settingsData;
  const access_token = props.access_token;
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [wrapper, setWrapper] = React.useState(settingsData.wrapper_disabled == 1 ? true : false);
  var updateobj = {
    brands: [],
    adsets: [],
    campaigns: []

  }
  let brands = [];
  const brandState = {};
  if (settingsData != undefined && settingsData.brands != undefined && settingsData.brands.length > 0) {
    brands = settingsData.brands;
    brands.forEach((item, index) => {
      brandState[index] = {};
      brandState[index].selected = item.block_wrapper_flag || 0;
      let campaignStateForSelectedBlock = {};
      item.campaigns.forEach((campaign, index) => {
        campaignStateForSelectedBlock[index] = {};
        let campaign_state = campaign.block_wrapper_flag;
        campaignStateForSelectedBlock[index].selected = campaign_state;
        const adsetStateForSelectedBlock = {};
        campaign.adsets.forEach((adset, index) => {
          adsetStateForSelectedBlock[index] = {};
          adsetStateForSelectedBlock[index].selected = adset.block_wrapper_flag || 0;
        });
        campaignStateForSelectedBlock[index].adsets = adsetStateForSelectedBlock;
      });
      brandState[index].campaigns = campaignStateForSelectedBlock;
    });
  }
  const [initbrandflag, initsetBrandFlag] = React.useState(brandState);
  const [brandflag, setBrandFlag] = React.useState(brandState);
  const [openErrors, setOpenErrors] = React.useState(false);
  useEffect(() => {
    if (props.showErrorModal) {
      //setErrorMessage(props.error);
      setOpenErrors(true);
    }
    else {
      setOpenErrors(false);
    }
  });
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

  }

  const handleInnerChange = panel => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false);

  }
  const handleChangeDisable = (event) => {
    if (event.target.checked) {
      setWrapper(true);
    }
    else {
      setWrapper(false);
    }
    handleSubmit({ "wrapper_disabled": event.target.checked ? 1 : 0 });
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000)
  }
  const handleChangeDisableCampaigns = (event, index1, index2) => {
    const selectedBrand = brandflag[index1];
    selectedBrand.campaigns[index2].selected = event.target.checked;

    if (event.target.checked) {
      Object.keys(selectedBrand.campaigns[index2].adsets).forEach(key => selectedBrand.campaigns[index2].adsets[key].selected = true);
    } else {
      Object.keys(selectedBrand.campaigns[index2].adsets).forEach(key => selectedBrand.campaigns[index2].adsets[key].selected = false);
    }

    setBrandFlag({
      ...brandflag,
      [index1]: {
        ...selectedBrand
      }
    });
    handleSubmit({ "campaigns": [{ value: event.target.checked ? 1 : 0, id: brands[index1].campaigns[index2].campaign_id, brand_id: brands[index1].brand_id }] });
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000)
  }
  const handleChangeDisableBrands = (event, id, index) => {
    event.stopPropagation();
    const selectedBrand = brandflag[index];
    selectedBrand.selected = event.target.checked;
    if (event.target.checked) {
      Object.keys(selectedBrand.campaigns).forEach(key => {
        selectedBrand.campaigns[key].selected = true;
        Object.keys(selectedBrand.campaigns[key].adsets).forEach(key1 => selectedBrand.campaigns[key].adsets[key1].selected = true);
      });
      handleSubmit({ "brands": [{ value: 1, id: brands[index].brand_id }] });
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000)
    } else {
      Object.keys(selectedBrand.campaigns).forEach(key => {
        selectedBrand.campaigns[key].selected = false;
        Object.keys(selectedBrand.campaigns[key].adsets).forEach(key1 => {
          selectedBrand.campaigns[key].adsets[key1].selected = false;
        })
      });
      handleSubmit({ "brands": [{ value: 0, id: brands[index].brand_id }] });
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000)
    }

    setBrandFlag({
      ...brandflag,
      [index]: {
        ...selectedBrand
      }
    });

  }
  const handleChangeDisableAdsets = (event, index1, index2, index3) => {
    const selectedBrand = brandflag[index1];
    selectedBrand.campaigns[index2].adsets[index3].selected = event.target.checked;
    let flag = event.target.checked ? 1 : 0;
    setBrandFlag({
      ...brandflag,
      [index1]: {
        ...selectedBrand
      }
    });
    handleSubmit({ "adsets": [{ id: brands[index1].campaigns[index2].adsets[index3].adset_id, value: flag, brand_id: brands[index1].brand_id }] });
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000)
  };

  const handleSubmit = (obj) => {
    // console.log(brandflag);
    // var obj = {
    //   "wrapper_disabled": wrapper == true ? 1 : 0,
    //   "campaigns": [],
    //   "brands": [],
    //   "adsets": []
    // }
    // brands.forEach((brand, index) => {
    //   if (brandflag[index].selected == true) {
    //     obj.brands.push({ id: brand.brand_id, value: 1 });
    //   }
    //   else {
    //     obj.brands.push({ id: brand.brand_id, value: 0 });
    //   }

    // })


    // Object.keys(brandflag).forEach((key) => {
    //   Object.keys(brandflag[key].campaigns).forEach((key1) => {
    //     if (brandflag[key].campaigns[key1].selected) {
    //       obj.campaigns.push({ id: brands[key].campaigns[key1].campaign_id, value: 1, brand_id: brands[key].brand_id })
    //     }
    //   })
    // })


    // Object.keys(brandflag).forEach((key) => {
    //   Object.keys(brandflag[key].campaigns).forEach((key1) => {
    //     Object.keys(brandflag[key].campaigns[key1].adsets).forEach((key2) => {
    //       if (brandflag[key].campaigns[key1].adsets[key2].selected) {
    //         obj.adsets.push({ id: brands[key].campaigns[key1].adsets[key2].adset_id, value: 1, brand_id: brands[key].brand_id })
    //       }
    //     })
    //   })
    // })
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${access_token}`,// eyJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NfdG9rZW4iOiJkNjY5NDQzYjJkMDZkZGU2NzgyNjgwNWIwNTFjNWRiMiIsImV4cCI6MTU3MTczMjIyMH0.wZIENpLi--kQiHiz4v2zHKjK8pqKs3Z3OPhyI7hmftY'
    };
    var LOGIN_API_URL = serviceUrl + "/settings/update";
    axios.put(LOGIN_API_URL, obj, { headers: headers }).then(response => {
    })
      .catch(err => {
        console.log(err);
      });

  }
  const headCells = {
    Campaignheads: [
      { id: 'campaignid', numeric: true, disablePadding: true, label: 'CampaignId' },
      { id: 'name', numeric: true, disablePadding: false, label: 'Campaign Name' },
      { id: 'disable', numeric: true, disablePadding: false, label: 'Block ' }
    ],
    Adheads: [
      { id: 'adid', numeric: true, disablePadding: true, label: 'AdId' },
      { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
      { id: 'disable', numeric: true, disablePadding: false, label: 'Block ' }
    ],
    Brandheads: [
      { id: 'id', numeric: true, disablePadding: true, label: 'BrandId' },
      { id: 'name', numeric: true, disablePadding: false, label: 'Brand Name' },
      { id: 'disable', numeric: true, disablePadding: false, label: 'Block ' }
    ],
    AdSetheads: [
      { id: 'adsetid', numeric: true, disablePadding: true, label: 'AdsetId' },
      { id: 'name', numeric: true, disablePadding: false, label: 'Adset Name' },
      { id: 'disable', numeric: true, disablePadding: false, label: 'Block ' }
    ]

  };

  return (
    <div className={classes.root}>
      {open ? <MySnackbarContentWrapper {...props} /> : null}
      {openErrors ? <CustomizedSnackbars message={props.error} {...props} /> : null}
      {settingsData.brands ? settingsData.brands.length > 0 ?
        <Fragment>
          <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h6" color="primary">General Settings</Typography>
              <Typography className={classes.secondaryHeading}>Block Adwrapper on all Ads.</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <FormControlLabel
                  control={<Switch checked={wrapper} onChange={handleChangeDisable} />}
                  label="Block Wrapper"
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography variant="h6" color="primary">Ad Brand Settings</Typography>
              <Typography className={classes.secondaryHeading}>
                Block Adwrapper on Ad Brands
                            </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                {settingsData.brands.length > 0 ? '' : 'No data to display'}</div>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
              >

                <TableBody>
                  {brands.map((row, index) => {
                    //const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <ExpansionPanel key={index} className={classes.campaign_panel}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id={row.brand_id}

                        ><TableRow className={classes.panel_height}
                          hover
                          //onClick={event => handleClick(event, row.name)}
                          role="checkbox"
                          //aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.brand_id}
                        // selected={isItemSelected}
                        >
                            <TableCell className="removeBorder" align="right"><b>BrandId : </b>{row.brand_id}</TableCell>
                            <TableCell className="removeBorder" align="right"><b>BrandName : </b>{row.brand_name}</TableCell>
                            <TableCell className="removeBorder" align="right"><div>
                              <b>Block : </b>
                              <FormControlLabel
                                control={<Switch checked={brandflag[index].selected ? true : false} onClick={event => event.stopPropagation()} onFocus={event => event.stopPropagation()} onChange={(event) => handleChangeDisableBrands(event, row.brand_id, index)} />}
                              />
                            </div></TableCell></TableRow>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          {/* <div>{row.campaigns.length ? '' : 'No data to display'}</div> */}
                          <Table
                            className={classes.tablecampaigns}
                            aria-labelledby="tableTitle"
                          >

                            <TableBody>
                              {row.campaigns.map((row, index2) => {
                                const labelId = `enhanced-table-checkbox-${index2}`;
                                return (


                                  <ExpansionPanel key={index2} className={classes.campaign_panel} onChange={handleInnerChange('panel2')}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel4bh-content"
                                      id={row.campaign_id}
                                    ><TableRow
                                      hover
                                      //onClick={event => handleClick(event, row.name)}
                                      role="checkbox"
                                      //aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.campaign_id}
                                    // selected={isItemSelected}
                                    >
                                        <TableCell className="removeBorder" align="right"><b>Campaign Id: </b>{row.campaign_id}</TableCell>
                                        <TableCell className="removeBorder" align="right"><b>Campaign Name: </b>{row.campaign_name}</TableCell>
                                        <TableCell className="removeBorder" align="right"><div>
                                          <b>Block:</b>
                                          <FormControlLabel
                                            control={<Switch checked={brandflag[index].campaigns[index2].selected ? true : false} disabled={brandflag[index].selected ? true : false} onClick={event => event.stopPropagation()} onFocus={event => event.stopPropagation()} onChange={(event) => handleChangeDisableCampaigns(event, index, index2)} />}

                                          />
                                        </div></TableCell>
                                      </TableRow>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="marginLeft">
                                      {/* <div>{row.adsets.length ? '' : 'No data to display'}</div> */}
                                      <Table
                                        className={classes.tablecampaigns}
                                        aria-label="medium table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="left"><b>Adset ID</b></TableCell>
                                            <TableCell align="left"><b>Adset Name</b></TableCell>
                                            <TableCell align="left"><b>Block</b></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.adsets.map((row1, index3) => {
                                            const labelId = `enhanced-table-checkbox-${index3}`;
                                            return (
                                              <TableRow
                                                hover
                                                //onClick={event => handleClick(event, row.name)}
                                                role="checkbox"
                                                //aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index3}
                                              // selected={isItemSelected}
                                              >
                                                <TableCell align="left">{row1.adset_id}</TableCell>
                                                <TableCell align="left">{row1.adset_name}</TableCell>
                                                <TableCell align="left"><div>

                                                  <FormControlLabel
                                                    control={<Switch checked={brandflag[index].campaigns[index2].adsets[index3].selected ? true : false} disabled={brandflag[index].campaigns[index2].selected ? true : false} onChange={(event) => handleChangeDisableAdsets(event, index, index2, index3)} />}

                                                  />
                                                </div></TableCell>
                                              </TableRow>)
                                          })}

                                        </TableBody>
                                      </Table>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                );
                              }


                              )}
                            </TableBody>

                          </Table>

                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  })}
                </TableBody>

              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel><div className={classes.submit_btn} >
          </div> </Fragment> : <center>No data to display</center> : <center>No data to display</center>}
    </div>

  );

}

export default Settings;