import React, { Fragment, PureComponent } from "react";
import Modal from 'react-bootstrap/Modal';
import SignIn from '../loginpage/Signin';
import { lighten, makeStyles } from '@material-ui/core/styles';

const modalStyles = makeStyles(theme => ({
    modal70w: {
        width: 500
    }
})
)

function SigninModal(props) {
    const classes = modalStyles();
    const [modalShow3, setModalShow3] = React.useState(props.modalshow);
    const handleClose = () => {
        setModalShow3(false);
       
    };
    return (
        <Modal onHide={handleClose} show={props.modalshow} style={props.style
        }
            dialogClassName={classes.modal70w}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body >
                <SignIn {...props} />
            </Modal.Body>
        </Modal>
    );
}

export default SigninModal;