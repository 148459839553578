import React, { useEffect } from 'react'
//import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomizedSnackbars from '../MySnackbarContentWrapper';

//import ReactHeader from './ReactHeader';
//mport asyncValidate from './asyncValidate'


const useStyles = makeStyles(theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: 20
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    icon_color: {
        color: '#1976d2'
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        paddingTop: 8,
        paddingBottom: 8
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        fontSize: 14,
        padding: 20
    },
    page_title: {
        fontSize: 14
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 36,
        backgroundColor: '#1976d2',
        fontSize: 14
    },
    FroalaEditor: {
        paddingTop: 8,
        paddingBottom: 8
    },
    select: {
        width: 150,
        paddingTop: 8,
        paddingBottom: 8
    }
}));


const EditWrapperForm = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openErrors, setOpenErrors] = React.useState(false);
    const { id, name, description, pre_condition, position, enable, app_id } = props.wrapperFields;
    useEffect(() => {
        if (props.showErrorModal) {
            //setErrorMessage(props.error);
            setOpenErrors(true);
        }
        else {
            setOpenErrors(false);
        }
    });

    const [errormessage, setErrorMessage] = React.useState('');
    const apps = props.appsData;
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [state, setState] = React.useState({
        app_id: app_id,
        enable: enable,
        position: position
    });
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = name => event => {
        console.log(event.target.value);
        setState({
            ...state,
            [name]: event.target.value
        }
        );
        handleFieldChangeWrapper(event);
    };
    const handleSubmit = (event) => {
        if (name == '') {
            setErrorMessage('Name is required');
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 4000)
        }
        else {
            props.handleSubmit(event, 'editwrapper', id);
        }

    }

    /* props.wrapperFields.app_id = state.app_id;
    props.wrapperFields.enable = state.enable;
    props.wrapperFields.position = state.position; */
    const { handleFieldChangeWrapper, pristine, reset, submitting } = props;

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" >
                        Edit Wrapper Form
                    </Typography>
                    <ValidatorForm onSubmit={(event) => handleSubmit(event, 'editwrapper', id)} noValidate>
                        <div className={classes.input}>
                            <TextField
                                name="name"
                                //component={renderTextField}
                                autoFocus
                                label="Name"
                                value={name}
                                onChange={handleFieldChangeWrapper}
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.input}>
                            <TextField name="description" label="Description" value={description} fullWidth
                                onChange={handleFieldChangeWrapper} variant="outlined" />
                        </div>
                        <div className={classes.input}>
                            <TextField name="pre_condition" label="Pre-Condition" value={pre_condition} fullWidth
                                onChange={handleFieldChangeWrapper} variant="outlined" />
                        </div>
                        <div>
                            <FormControl variant="outlined" className={classes.select}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
                                    Position
                                </InputLabel>
                                <Select
                                    native
                                    name="position"
                                    value={position}
                                    onChange={handleChange('position')}
                                    labelWidth={labelWidth}

                                >
                                    <option value=''></option>
                                    {/* <option value={3}>Left</option> */}
                                    <option value={1}>Bottom</option>
                                    {/* <option value={2}>Right</option> */}
                                    <option value={0}>Top</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div >
                            <FormControl variant="outlined" className={classes.select}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
                                    Enable
                                </InputLabel>
                                <Select
                                    native
                                    name="enable"
                                    value={enable}
                                    onChange={handleChange('enable')}
                                    labelWidth={labelWidth}

                                >
                                    <option value=''></option>
                                    <option value={1}>True</option>
                                    <option value={0}>False</option>
                                </Select>
                            </FormControl>
                        </div>
                        <div >
                            <FormControl variant="outlined" className={classes.select}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
                                    App Name
                                </InputLabel>
                                <Select
                                    native
                                    name="app_id"
                                    value={app_id}
                                    onChange={handleChange('app_id')}
                                    labelWidth={labelWidth}

                                >
                                    <option value=''></option>
                                    {
                                        apps.map((app, index) => {
                                            return <option key={index} value={app.id}>{app.name}</option>

                                        })}
                                </Select>
                            </FormControl>
                        </div>
                        {open ? <CustomizedSnackbars message={errormessage} {...props} /> : null}
                        {openErrors ? <CustomizedSnackbars message={props.error} {...props} /> : null}
                        <div>
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                Submit
                            </Button>
                            {/* <Button variant="contained" className={classes.button} disabled={pristine || submitting} onClick={(event) => reset(event, 'editwrapper')}>
                                Clear Values
                            </Button> */}
                        </div>
                    </ValidatorForm>
                </Paper>
            </div>
        </React.Fragment>
    )
}

export default EditWrapperForm;//({
    //form: 'MaterialUiForm', // a unique identifier for this form
    //validate,
    //asyncValidate
//})(MaterialUiForm)
