import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));
function ErrorHandleSnackbars(props) {
  console.log(props);
  const classes = useStyles2();
  //const [open, setOpen] = React.useState(props.showErrorModal);

  const handleClick = () => {
    //setOpen(true);
  };

  const handleClose = (event, reason) => {
    setModalShow3(false);
    props.handleCloseModal();
  };
  return (
    <div>
      <MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={props.error}
      //onClose={handleClose}
      />
    </div>)

}

export default function CustomizedSnackbars(props) {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      props.handleCloseModal();
    }
    console.log("Hello");
    setOpen(false);
  };

  switch (props.currentpage) {
    case 'CreateApp':
      return (<div><MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={props.message || props.error}
        onClose={(event) => handleClose(event, 'clickaway')}
      /></div>);
    case 'EditApp':
      return (<div><MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={props.message || props.error}
        onClose={(event) => handleClose(event, 'clickaway')}
      /></div>);
    case 'CreateWrapper':
      return (<div><MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={props.message || props.error}
        onClose={(event) => handleClose(event, 'clickaway')}
      /></div>);
    case 'EditWrapper':
      return (<div><MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={props.message || props.error}
        onClose={(event) => handleClose(event, 'clickaway')}
      /></div>);
    case 'Settings':
      if (props.showErrorModal) {
        return (<div><MySnackbarContentWrapper
          variant="error"
          className={classes.margin}
          message={props.message || props.error}
          onClose={(event) => handleClose(event, 'clickaway')}
        /></div>);
      }
      return (<div><MySnackbarContentWrapper
        variant="success"
        className={classes.margin}
        message="Record updated successfully"
        open={open}
        onClose={handleClose}
      /></div>);
    case 'App':
      return (<div>
        <MySnackbarContentWrapper
          variant="error"
          className={classes.margin}
          message={props.error}
          onClose={(event) => handleClose(event, 'clickaway')}
        />
      </div>);
    default: return (<div><MySnackbarContentWrapper
      variant="error"
      className={classes.margin}
      message={props.message || props.error}
      onClose={(event) => handleClose(event, 'clickaway')}
    /></div>);
  }
  //return (
  //<div>
  {/* <Button variant="outlined" className={classes.margin} onClick={handleClick}>
        Open success snackbar
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'Center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      /> */}
  {/* <MySnackbarContentWrapper
          onClose={handleClose}
          variant="success"
          message="This is a success message!"
      />
      </Snackbar>
      <MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={props.message}
      />
      {/*<MySnackbarContentWrapper
        variant="warning"
        className={classes.margin}
        message="This is a warning message!"
      />
      <MySnackbarContentWrapper
        variant="info"
        className={classes.margin}
        message="This is an information message!"
      />
      } */}
  {/* <MySnackbarContentWrapper
        variant="success"
        className={classes.margin}
        message="Record updated successfully"
        open={open}
        onClose={handleClose}
      /> 
    </div>*/}
  //);
}