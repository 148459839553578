import ACTIONS from './constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SIGNUP_ERROR_OCCURRED:
            return { ...state, errorMsg: action.payload };
        case ACTIONS.CLEAR_SIGNUP_ERROR:
            return { ...state, errorMsg: undefined };

        default:
            return { ...state };
    }
}