import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as loginActionCreator from './actions';
import axios from 'axios';
//import ROUTES from '../utils/routeConstants';
////import getQueryString from '../utils/getQueryString';
//import getPlatformId from '../utils/getPlatformId';
import SignIn from './Signin';
//import ClearDemandForm from './ClearDemandForm';



const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST";
const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
const ROUTES = {
    SignIn: '/signin',
    SIGNUP: '/signup',
    FORGOT_PASSWORD: '/forgotPassword',
    SET_PASSWORD: '/setPassword'
};

class LoginIndex extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''

        };
        this.serviceUrl = 'https://cd-sso-api-dev.pymx6.com/users/login?service=http://coriolis.co.in';//this.getServiceUrl();
        this.redirectFrom = '';
        //this.isServiceUrlValid = this.checkValidUrl(this.serviceUrl);
        //this.platform = getPlatformId(this.serviceUrl);

        //window.localStorage.setItem('serviceUrl', this.serviceUrl);
        //localStorage.setItem('platform', this.platform);
    }

    checkValidUrl(input) {
        return /^((?:http(s)?:\/\/)|(www)\.)[(a-z)(0-9)-]*(?:\.[(a-z).-]+)+[(\w\-_~:/?&=.]+$/gi.test(input);
    }

    validateForm() {
        console.log('validation');
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    getServiceUrl = () => {
        const urlParams = getQueryString();
        this.redirectFrom = urlParams.action || '';
        return (urlParams.serviceUrl && decodeURIComponent(urlParams.serviceUrl)) || '';
    }

    handleFieldChange = (e) => {
        console.log('inside handleFieldChange');
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleSubmit = () => {
        event.preventDefault();
        console.log('inside handlesubmit');
        console.log(this.props);
        const { sendLoginRequest } = this.props;

        const { email, password } = this.state;
        sendLoginRequest({ email, password }, this.serviceUrl, this.redirectFrom);
        this.getLogin({ email, password });

    }
    getLogin(obj) {
        console.log('Login Action');
        const headers = {
            'Content-Type': 'application/json'
        };
        var LOGIN_API_URL = "https://cd-sso-api-dev.pymx6.com/users/login?service=http://coriolis.co.in";
        if (this.validateForm()) {
            //return dispatch => {
            //dispatch({ type: 'SEND_LOGIN_REQUEST' });
            const { loginCompleted } = this.props;
            return axios.post(LOGIN_API_URL, obj, { header: headers })
                .then(response => response.data)
                .then(res => {
                    if (res.status.status_code == 302) {
                        loginCompleted(res);
                    }
                    else if (res.status.status_code == 1106) {
                        alert('Email or password is not correct');
                        location.reload();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            /* return myAjaxLib.post(`https://cd-sso-api-dev.pymx6.com/users/login?service=http://coriolis.co.in`, obj)
                .then(response => response.json())
                .then(json => dispatch(getLoginSuccess(json)))
                .catch(error => console.log(error)); */
        }
        else {
            alert('Form cannot be submitted!');
        }
    };

    componentDidMount() {
        console.log('Component Mounted');

        const { getLoginRequest, setLoginError } = this.props;
        //getLoginRequest(this.serviceUrl);
        /* if (this.isServiceUrlValid) {
            
        } else {
            setLoginError('Service URL is not valid.');
        } */
    }

    render() {
        const { email, password } = this.state;
        const { errorMsg, clearLoginError, isLoggedIn, actualRoute } = this.props;
        const inputProps = {
            email,
            password,
            handleFieldChange: this.handleFieldChange,
            handleSubmit: this.handleSubmit,
            error: errorMsg,
            unsetError: clearLoginError,
            disableForm: !this.validateForm,
            /* forgetPasswordLink: {
                pathname: ROUTES.FORGOT_PASSWORD,
                state: {
                    platformId: this.platform,
                },
            }, */
        };

        if (isLoggedIn) {
            console.log('inside isLoggedIn')
            //if (actualRoute) {
            return <Redirect to={'/hello'} />;
            //}
            //return <Redirect to='' />;
        }

        return (
            <Fragment>
                {

                    <SignIn {...inputProps} />
                    //<ClearAttentionForm {...inputProps} />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn: state.isLoggedIn,
    //actualRoute: state.app.actualRoute,
    //errorMsg: state.login.errorMsg,
    //loginFormShow: state.login.loginFormShow,
});

const mapDispatchToProps = dispatch => ({
    loginCompleted: (data) => dispatch(loginActionCreator.loginCompleted(data)),
    getLoginRequest: serviceUrl => dispatch(loginActionCreator.getLoginRequest(serviceUrl)),
    sendLoginRequest: (loginDetails, serviceUrl, redirectFrom) => dispatch(loginActionCreator.sendLoginRequest(loginDetails, serviceUrl, redirectFrom))
})
//const mapDispatchToProps = { getLogin };

export default connect(mapStateToProps, mapDispatchToProps)(LoginIndex);
