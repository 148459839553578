import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        fontSize: 14
    },
    page_title: {
        fontSize: 23
    },
    svg_icon: {
        height: 24,
        width: 24
    },
    routelink: {
        color: '#1976d2',
        fontSize: 14
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 36,
        backgroundColor: '#1976d2',
        fontSize: 14
    }
}));

export default function SignUp(props) {
    const classes = useStyles();
    const {
        fullname, email, password, handleFieldChange, handleSubmit, error, unsetError, disableForm } = props;
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon className={classes.svg_icon} />
                </Avatar>
                <Typography component="h1" variant="h5" className={classes.page_title} >
                    Sign up
        </Typography>
                <ValidatorForm className={classes.form} onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextValidator
                                autoComplete="fname"
                                name="fullname"
                                variant="outlined"
                                required
                                fullWidth
                                id="fullname"
                                label="Full Name"
                                value={fullname}
                                onChange={(event) => handleFieldChange(event, 'name')}
                                autoFocus
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={(event) => handleFieldChange(event, 'email')}
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(event) => handleFieldChange(event, 'password')}
                                validators={['required']}
                                errorMessages={['this field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.mt}>
                        {error
                            && (
                                <Message
                                    color="error"
                                    onClose={unsetError}
                                >{error}</Message>
                            )
                        }
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={disableForm}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/signin" variant="body2" className={classes.routelink}>
                                Already have an account? Sign in
              </Link>
                        </Grid>
                    </Grid>

                </ValidatorForm>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

