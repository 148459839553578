import React, { Fragment, PureComponent } from "react";
import Modal from 'react-bootstrap/Modal';
import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const modalStyles = makeStyles(theme => ({
    modal70w: {
        width: 500
    },
    error_title: {
        backgroundColor: 'red'
    }
})
)

function SuccessHandleModal(props) {
    console.log(props);
    const classes = modalStyles();
    const [modalShow3, setModalShow3] = React.useState(props.showSuccessModal);
    const handleClose = () => {
        setModalShow3(false);
        props.handleCloseModal();
    };
    return (
        <Modal onHide={handleClose} show={props.showSuccessModal}
            //style={props.style}
            dialogClassName={classes.modal70w}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header>
                
            </Modal.Header> */}
            <Modal.Body >
                <Modal.Title id="contained-modal-title-vcenter" >
                    Success!
                </Modal.Title><br></br>
                {props.error}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button type="submit" variant="contained" onClick={handleClose} >
                        Close
                </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default SuccessHandleModal;