import React, { useEffect } from 'react'
//import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import CustomizedSnackbars from '../MySnackbarContentWrapper';


const useStyles = makeStyles(theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: 20
    },
    table: {
        minWidth: 750,
    },
    tableApp: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    icon_color: {
        color: '#1976d2'
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        paddingTop: 8,
        paddingBottom: 8
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        fontSize: 14,
        padding: 20
    },
    page_title: {
        fontSize: 14
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 36,
        backgroundColor: '#1976d2',
        fontSize: 14
    },
    FroalaEditor: {
        paddingTop: 8,
        paddingBottom: 8
    },
    action_group: {
        paddingLeft: 50,
        paddingTop: 8,
        paddingBottom: 8,

    }
}));


const EditAppForm = props => {
    const classes = useStyles();
    console.log(props);
    const [open, setOpen] = React.useState(false);
    const [openErrors, setOpenErrors] = React.useState(false);
    useEffect(() => {
        if (props.showErrorModal) {
            //setErrorMessage(props.error);
            setOpenErrors(true);
        }
        else {
            setOpenErrors(false);
        }
    });

    const [errormessage, setErrorMessage] = React.useState('');
    const { handleFieldChangeEditApp, pristine, reset, submitting } = props;
    const { id, name, description, post_actions, html, action_type, post_actions_html } = props.appFieldsEdit;
    const config = {
        valid_children: "+body[style]",
        valid_elements: '*[*]',
        extended_valid_elements: "script[src|async|defer|type|charset]",
        inline_styles: true,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount', 'code'
        ],
        toolbar:
            'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | code | help'
    };

    const handleSubmit = (event) => {
        if (name == '') {
            setErrorMessage('Name is required');
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 4000)
        }
        else {
            props.handleSubmit(event, 'editapp', id);
        }

    }

    /* function allIndexOf(str, toSearch) {
        var indices = [];
        for (var pos = str.indexOf(toSearch); pos !== -1; pos = str.indexOf(toSearch, pos + 1)) {
            indices.push(pos);
        }
        return indices;
    }
    const action_item = [];
    const action_indices = allIndexOf(html, 'actions');
    action_indices.forEach((arr, index) => {
        action_item[index] = '';
        while (html[arr - 1] != '\"') {
            if (html[arr - 1] != ' ') {
                action_item[index] += html[arr - 1];

            }
            arr--;
        }
    });
    const post_actions_id = action_item.map((action, index) => {
        var splitString = action.split("");
        var reverse = splitString.reverse();
        return reverse.join("");
    });
    props.appFields.post_actions_id = post_actions_id[0];
 */

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h6">
                        Edit App Form
                    </Typography>
                    <ValidatorForm onSubmit={(event) => handleSubmit(event, 'editapp', id)} noValidate>
                        <div className={classes.input}>
                            <TextField
                                name="name"
                                //component={renderTextField}
                                autoFocus
                                label="Name"
                                value={name}
                                onChange={handleFieldChangeEditApp('name')}
                                fullWidth
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.input}>
                            <TextField name="description" label="Description" value={description} fullWidth
                                onChange={handleFieldChangeEditApp('description')} variant="outlined" />
                        </div>

                        <div className={classes.FroalaEditor}>
                            <InputLabel >
                                Html:
                            </InputLabel>
                            <br></br>
                            <Editor
                                initialValue={html}
                                init={config}
                                onChange={handleFieldChangeEditApp('html')}
                            />
                        </div>
                        {post_actions[0] ? <InputLabel>Decorators:</InputLabel> : ''}
                        {post_actions[0] ?
                            <div className={classes.action_group} >

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[0].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 0)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <TextField name="post_actions_rule" label="Post Condition" value={post_actions[0].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 0)} fullWidth
                                        variant="outlined" />
                                </div>
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[0].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 0)}
                                    />
                                </div>
                            </div> : ''}
                        {post_actions[1] ?
                            <div className={classes.action_group}>

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[1].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 1)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <TextField name="post_actions_rule" label="Post Condition" value={post_actions[1].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 1)} fullWidth
                                        variant="outlined" />
                                </div>
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[1].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 1)}
                                    />
                                </div>
                            </div> : ''}
                        {post_actions[2] ?
                            <div className={classes.action_group}>

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[2].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 2)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                <TextField name="post_actions_rule" label="Post Condition" value={post_actions[2].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 2)} fullWidth
                                    variant="outlined" />
                            </div> 
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[2].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 2)}
                                    />
                                </div>
                            </div> : ''}
                        {post_actions[3] ?
                            <div className={classes.action_group}>

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[3].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 3)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                <TextField name="post_actions_rule" label="Post Condition" value={post_actions[3].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 3)} fullWidth
                                    variant="outlined" />
                        </div>
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[3].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 3)}
                                    />
                                </div>
                            </div> : ''}
                        {post_actions[4] ?
                            <div className={classes.action_group}>

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[4].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 4)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                <TextField name="post_actions_rule" label="Post Condition" value={post_actions[4].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 4)} fullWidth
                                    variant="outlined" />
                        </div>
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[4].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 4)}
                                    />
                                </div>
                            </div> : ''}
                        {post_actions[5] ?
                            <div className={classes.action_group}>

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[5].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 5)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <TextField name="post_actions_rule" label="Post Condition" value={post_actions[5].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 5)} fullWidth
                                        variant="outlined" />
                                </div>
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[5].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 5)}
                                    />
                                </div>
                            </div> : ''}
                        {post_actions[6] ?
                            <div className={classes.action_group}>

                                <div className={classes.input}>
                                    <TextField
                                        name="action_type"
                                        //component={renderTextField}
                                        value={post_actions[6].action_type}
                                        onChange={handleFieldChangeEditApp('action_type', 6)}
                                        label="Action"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <TextField name="post_actions_rule" label="Post Condition" value={post_actions[6].rule}
                                        onChange={handleFieldChangeEditApp('post_actions_rule', 6)} fullWidth
                                        variant="outlined" />
                                </div>
                                <div className={classes.FroalaEditor}>
                                    <InputLabel >
                                        Post Actions Html:
                            </InputLabel>
                                    <Editor
                                        initialValue={post_actions[6].html}
                                        init={config}
                                        onChange={handleFieldChangeEditApp('html1', 6)}
                                    />
                                </div>
                            </div> : ''}
                        {open ? <CustomizedSnackbars message={errormessage} {...props} /> : null}
                        {openErrors ? <CustomizedSnackbars message={props.error} {...props} /> : null}
                        <div>
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                Submit
                            </Button>
                            {/* <Button variant="contained" className={classes.button} disabled={pristine || submitting} onClick={(event) => reset(event, 'editapp')}>
                                Clear Values
                            </Button> */}
                        </div>
                    </ValidatorForm>
                </Paper>
            </div>
        </React.Fragment >
    )
}

export default EditAppForm//({
    //form: 'MaterialUiForm', // a unique identifier for this form
    //validate,
    //asyncValidate
//})(MaterialUiForm)
