//import ACTIONS from './constants';

export const getLoginRequest = (serviceUrl) => {
    return {
        type: 'GET_LOGIN_REQUEST',
        payload: serviceUrl
    };
}

export const sendLoginRequest = (loginDetails, serviceUrl, redirectFrom) => {

    return {
        type: 'SEND_LOGIN_REQUEST',
        loginDetails: loginDetails,
        serviceUrl: serviceUrl,
        redirectFrom: redirectFrom
    };
}

export const loginCompleted = (data) => {
    return {
        type: 'LOGIN_COMPLETED',
        payload: data
    };
}

export const logoutCompleted = () => {
    return {
        type: 'LOGOUT_COMPLETED'
        //payload: data
    };
}

export const loginErrorOccurred = (errors) => {
    return {
        type: 'LOGIN_ERROR_OCCURRED',
        payload: errors
    };
}

export const clearHTTPError = () => {
    return {
        type: 'CLEAR_ERROR',
        props: 'CLEAR_ERROR'
    };
}

const setError = (payload) => ({
    type: 'SET_ERROR',
    payload,
});

const execute404Handler = (props) => {
    return {
        type: 'HTTP_404_ERROR',
        props: 'Error404! Resource Not Found!'
    }
}

const execute500Handler = (props) => {
    return {
        type: 'HTTP_500_ERROR',
        props: 'Error500! Internal Server Error!'
    }
}

const execute422Handler = (props) => {
    return {
        type: 'HTTP_422_ERROR',
        props: props
    }
}
const execute401Handler = (props) => {
    return {
        type: 'HTTP_401_ERROR',
        props: props
    }
}
const executeOtherErrorHandler = (error) => {
    return {
        type: 'HTTP_OTHER_ERROR',
        error: error
    }
}

export const handleHTTPError = (error, props) => {
    if (error == 'clear') {
        return clearHTTPError();
    }
    else if (error.response.status === 401) {
        return execute401Handler(props);
    }
    else if (error.response.status === 404) {
        return execute404Handler(props);
    }
    else if (error.response.status === 422) {
        return execute422Handler(props);
    }
    else if (error.response.status === 500) {
        return execute500Handler(props);
    }
    else {
        return executeOtherErrorHandler(error);
    }
}

export const successHTTP = (success, message) => {
    return {
        type: success,
        props: message
    };
}
