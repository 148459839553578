class Validator {
    email = {
        rules: [
            {
                test: (value) => {
                    return value;
                },
                message: '*required'
            },
            {
                test: (value) => {
                    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
                },
                message: 'invalid email'
            }
        ],
        errorMsg: '',
        valid: false,
        state: '',
        value: '',
        touched: false
    };

    password = {
        rules: [
            {
                test: (value) => {
                    return value;
                },
                message: '*required'
            },
        ],
        errorMsg: '',
        valid: false,
        state: '',
        value: '',
        touched: false
    };

};

export default Validator;