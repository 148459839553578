import React, { Component } from 'react';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    console.log("Inside event");
    this.props.handleSubmit({ email: this.state.email, password: this.state.password })
  }

  handleChange = event => {
    console.log("Inside event chnage");
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  render() {
    return (
      <div>
        Login
        <form>
          <input name="email" onChange={this.handleChange}></input>
          <button type="submit" value="Submit" onClick={this.handleSubmit.bind(this)} >Submit</button>
        </form>
      </div>
    )
  }
}

export default LoginForm;