//import { Actions } from "../actions/actionTypes";
const initialState = {
  isLoggedIn: sessionStorage.getItem('access_token') ? true : false,
  signUpSuccess: false,
  userRequestLoading: true,
  logindetails: {},
  apps: [
    {
      id: 1,
      name: 'Deepak'
    }
  ]
};


const rootReducer = (state = initialState, action) => {
  console.log(action.type);
  switch (action.type) {
    case "GET_LOGIN_REQUEST":
      return { ...state, apps: action.payload };
    case "SEND_LOGIN_REQUEST":
      return { ...state, logindetails: action.logindetails };
    case "LOGIN_COMPLETED": {
      return { ...state, isLoggedIn: true };
    }
    case "LOGOUT_COMPLETED": {
      return {
        ...state, isLoggedIn: false
      };
    }
    case "SIGNUP_COMPLETED": {
      return { ...state, apps: action.payload, signUpSuccess: true };
    }
    default:
      return { ...state }

  }
}

export default rootReducer;