//import * as actionTypes from '../actions/actionTypes';

const initialState = {
    showErrorModal: false,
    showSuccessModal: false,
    errorMessage: ''
}

const execute404 = (state, action) => {
    return {
        ...state,
        showErrorModal: true,
        errorMessage: action.props
    };
}

const execute500 = (state, action) => {
    return {
        ...state,
        showErrorModal: true,
        errorMessage: action.props
    };
}
const execute422 = (state, action) => {
    const errmsg = Object.keys(action.props).map(key => {
        return (key + " : " + action.props[key]);
    });
    return {
        ...state,
        showErrorModal: true,
        errorMessage: errmsg[0]
    };
}

const execute401 = (state, action) => {
    if (action.props.status != undefined) {
        return {
            ...state,
            showErrorModal: true,
            errorMessage: action.props.status.status_message
        };
    }
    else if (action.props.errors != undefined) {
        return {
            ...state,
            showErrorModal: true,
            errorMessage: action.props.errors + '.Please try LoggingIn Again'
        };
    }
    else {
        return {
            ...state,
            showErrorModal: true,
            errorMessage: 'Please try LoggingIn Again'
        };

    }
}
const executeOtherError = (state, action) => {
    return {
        ...state,
        showErrorModal: true,
        errorMessage: action.error.response
    };
}
const clearError = (state, action) => {
    return {
        ...state,
        showErrorModal: false,
        showSuccessModal: false,
        errorMessage: ''
    };
}
const successHTTP = (state, action) => {
    return {
        ...state,
        showSuccessModal: true,
        errorMessage: action.props
    };
}

const errorHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'Success!':
            return successHTTP(state, action);
        case 'HTTP_404_ERROR':
            return execute404(state, action);
        case 'HTTP_500_ERROR':
            return execute500(state, action);
        case 'HTTP_422_ERROR':
            return execute422(state, action);
        case 'HTTP_401_ERROR':
            return execute401(state, action);
        case 'HTTP_OTHER_ERROR':
            return executeOtherError(state, action);
        case 'CLEAR_ERROR':
            return clearError(state, action);
        default:
            return state;
    }
}

export default errorHandlerReducer;