import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from "../store/configureStore";
import Index from './index';
import LoginIndex from './loginpage/index';
import SignupPage from './signupage/index';
import EnhancedTable from './wrapperFiles/List';
import AppListTable from './appFiles/appList';
import ForgotpasswordPage from './forgotPasswordPage/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import Settings from './Settings';
import CreateWrapperForm from "./wrapperFiles/Createwrapper";
import EditWrapperForm from "./wrapperFiles/Editwrapper";



const store = configureStore();
class App extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/wrapper" render={() => <Index baseUrl={this.props.baseUrl} />} />
            <Route path="/addwrapper" render={() => <CreateWrapperForm />} />
            <Route path="/editwrapper" render={() => <EditWrapperForm />} />
            <Route path="/signup" render={() => <SignupPage />} />
            <Route path="/wrapperlist" render={() => <EnhancedTable />} />
            <Route path="/applist" render={() => <AppListTable />} />
            <Route path="/settings" render={() => <Settings />} />
            <Route path="/forgotPassword" render={() => <ForgotpasswordPage />} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App
