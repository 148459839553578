
class Validator {
    name = {
        rules: [
            {
                test: (value) => {
                    return value;
                },
                message: '*required'
            },
            {
                test: (value) => {
                    return value.length <= 25 && value.length >= 3
                },
                message: 'min length: 3 and max length: 25'
            },
            {
                test: (value) => {
                    return /^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/.test(value);
                },
                message: 'Only Alphabets allowed'
            }
        ],
        errorMsg: '',
        valid: false,
        state: '',
        value: '',
        touched: false
    };

    email = {
        rules: [
            {
                test: (value) => {
                    return value;
                },
                message: '*required'
            },
            {
                test: (value) => {
                    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
                },
                message: 'Invalid email'
            }
        ],
        errorMsg: '',
        valid: false,
        state: '',
        value: '',
        touched: false
    };

    password = {
        rules: [
            {
                test: (value) => {
                    return /^(?=.*[a-z])/.test(value);
                },
                message: 'Password should at least have one lower case letter'
            },
            {
                test: (value) => {
                    return /^(?=.*[A-Z])/.test(value);
                },
                message: 'Password should at least have one upper case letter'
            },
            {
                test: (value) => {
                    return /^(?=.*[0-9])/.test(value);
                },
                message: 'Password should at least have one number'
            },
            {
                test: (value) => {
                    return /^(?=.*[!@#$%^&*])/.test(value);
                },
                message: 'Password should at least have one special character'
            },
            {
                test: (value) => {
                    return value.length >= 8
                },
                message: 'Password should be minimum 8 characters long'
            },
            {
                test: (value) => {
                    return value.length < 16
                },
                message: 'Password cannot have more than 15 characters'
            },
            {
                test: (value) => {
                    return /^(?=.{8,15})/.test(value);
                },
                message: 'Password cannot have more than 15 characters'
            },
            /* {
                test: (value) => {
                    console.log(this.confirm_password);
                    if (this.confirm_password.touched && value !== this.confirm_password.value) {
                        this.confirm_password.valid = false;
                        this.confirm_password.errorMsg = 'Passwords do not match';
                    } else {
                        this.confirm_password.valid = true;
                        this.confirm_password.errorMsg = '';
                    }
                    return true;
                }
            } */
        ],
        errorMsg: '',
        valid: false,
        state: '',
        value: '',
        touched: false
    };

    /* confirm_password = {
        rules: [
            {
                test: (value) => {
                    return this.password.valid && value === this.password.value;
                },
                message: 'Passwords do not match'
            }
        ],
        errorMsg: '',
        valid: false,
        state: '',
        value: '',
        touched: false
    }; */

};

export default Validator;